<template>
  <div class="micro-web"></div>
</template>

<script>
import { start } from 'qiankun'
export default {
  name: 'mricoWeb',
  mounted() {
    console.log('OMSmricoWeb')
    if (!window.qiankunStarted) {
      console.log('子应用单独加载')
      window.qiankunStarted = true
      start({
        prefetch: ['purchase', 'orderManage', 'aftersale_web'],
      })
    }
  },
}
</script>
